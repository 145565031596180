import * as process from "process";

export const SITE_NAME = "Zur Institute";

export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
export const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID;
export const GTM_ENGINE_IS_RED_ID = process.env.NEXT_PUBLIC_GTM_ENGINE_IS_RED_ID;
export const GTM_ENGINE_IS_RED_CONVERSION_EVENT_ADD_TO_CART =
  process.env.NEXT_PUBLIC_GTM_ENGINE_IS_RED_CONVERSION_EVENT_ADD_TO_CART;
export const GTM_ENGINE_IS_RED_CONVERSION_EVENT_PURCHASE =
  process.env.NEXT_PUBLIC_GTM_ENGINE_IS_RED_CONVERSION_EVENT_PURCHASE;
export const META_ENGINE_IS_RED_PIXEL_ID = process.env.NEXT_PUBLIC_META_PIXEL_ID;
export const KLAVIYO_PUBLIC_API_KEY = process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY;
export const KLAVIYO_SIGN_UP_FORM_ID = process.env.NEXT_PUBLIC_KLAVIYO_SIGN_UP_FORM_ID;

export const MAIN_MENU_ID = process.env.NEXT_PUBLIC_MAIN_MENU_ID || "dGVybTo2NA==";
export const SECONDARY_MENU_ID = process.env.NEXT_PUBLIC_SECONDARY_MENU_ID || "dGVybToxOQ==";
export const HOME_HERO_MENU_ID = process.env.NEXT_PUBLIC_HOME_HERO_MENU_ID || "dGVybTo4Mg==";

export const NEWSLETTER_SIGNUP_URL = process.env.NEXT_ZUR_NEWSLETTER_SIGNUP_URL || "/newsletter";
export const NEW_SITE_AUTH_MESSAGE = `
  <strong>Have an existing account? If this is your first time logging in to our new system, start here.  
  If you have already changed your password, scroll down to the Log In section.</strong><br />
  <br />
  Before proceeding you will need to complete a one-time password reset and then re-enter the information on your My Profile page 
  or the information on your CE certificate will not be accurate.<br />
  <br />
  <strong>Please do not create a new account as a new account will not contain your existing courses and learning history.</strong><br />
  <br />
  If you do not remember the email you used to create your account, please contact <a href="mailto:support@zurinstitute.com">support@zurinstitute.com</a> and we will provide assistance.
  `;
export const UPDATE_PROFILE_MESSAGE =
  "Please review and update your profile. You <b>MUST</b> save your changes to confirm that your profile has been migrated successfully.";
export const CATEGORY_PPT = "Private Practice tools";
export const CATEGORY_TOKENS = "Tokens";
export const CATEGORY_CERTIFICATE = "Certificate Programs";
export const CATEGORY_SAVINGS_PACKAGES = "Savings Packages";
export const CATEGORY_EVENTS = "Events";
export const SIMPLE_TYPE_PRODUCT = "simple";
export const CREDITS_TYPE_PRODUCT = "credits";
export const BUNDLE_TYPE_PRODUCT = "bundle";
export const VARIABLE_TYPE_PRODUCT = "variable";
export const SUPPORT_EMAIL = "support@zurinstitute.com";
export const SUPPORT_TEL = "833-961-1344";
export const LOCATION = "Zur Institute, 321 S. Main St. #29 Sebastopol, CA 95472";
export const COURSE_DISCLAIMER = `The course materials are copyrighted. If you wish to use them in your teaching or presentations, please contact <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a> for permission.`;
export const EMAIL_PLACEHOLDER = "user@email.com";

export const INDIVIDUAL_COURSES_CATEGORY_ID = 87;

export const LS_KEY_HAS_LOGGED_IN = "zur-has-logged-in";

export const PAGE_LOADING_DELAY_MS = 100;

export const TIME_EXPIRED_COURSES_WORDING =
  "Zur Institute continually updates and creates new courses to reflect changes in codes of ethics, laws, research, and current thinking in the field.\n" +
  "All courses in this section were either discontinued, and/or purchased over three years ago — the allotted time to complete a course.\n" +
  "While you have continued access to the content, you will NO LONGER be able take the posttest or obtain CE credits for any courses not previously completed.";

export const TIME_EXPIRED_EXPLANATION =
  "While you still can view the content, you are no longer able to take the post test or earn CE credits.";

export const SUBSCRIPTION_NAME = "Membership";
export const SUBSCRIPTION_NAME_LOWER = SUBSCRIPTION_NAME.toLowerCase();
export const SUBSCRIPTION_NAME_PLURAL = `${SUBSCRIPTION_NAME}s`;
export const SUBSCRIPTION_SUBSCRIBE = "Join";
export const SUBSCRIPTION_AUTO_RENEWAL_TEXT = `Your ${SUBSCRIPTION_NAME_LOWER} will automatically renew at the end of the billing period. Cancel anytime.`;
